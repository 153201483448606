/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useStaticQuery, graphql } from 'gatsby'

import Helmet from '../../components/HelmetHelper'
import Container, { FluidContainer } from '../../components/Container'
import Text, { TEXT_SIZE_SMALL } from '../../components/content/Text'
import {
	THEME_TAUPE,
	THEME_PEACH,
	THEME_WHITE,
	colors,
	media,
} from '../../constants'
import Slide, {
	SLIDE_LAYOUT_MOCK_FULL_SCREEN,
	SLIDE_LAYOUT_FULL_BLEED_IMAGE,
} from '../../components/Slide'
import {
	LayoutSplitHeading,
	LayoutIndented,
	LayoutCol2,
	LayoutVerticalCadence,
	LAYOUT_V_CADENCE_SIZE_MEDIUM,
} from '../../components/content/Layout'
import WorkShowcaseMobile, {
	WORK_SHOWCASE_LAYOUT_MOBILE_4UP,
} from '../../components/content/WorkShowcaseMobile'
import FullBleedImage from '../../components/content/FullBleedImage'
import { InlineLink, StandaloneLink } from '../../components/content/Link'
import { provideAccentTheme } from '../../context/AccentThemeContext'
import Stat from '../../components/content/Stat'

import Showcase, {
	SHOWCASE_LAYOUT_1UP,
	SHOWCASE_LAYOUT_2UP,
} from '../../components/content/Showcase'

import { caseStudyLinkData } from '../../workData'

const nextPage = caseStudyLinkData.toryBurch.next

const ToryBurchPage = ({ accentTheme }) => {
	const data = useStaticQuery(graphql`
		query {
			collage1: file(relativePath: { eq: "tory-burch/tory-collage-1@2x.jpg" }) {
				...ImageFluidFull
			}
			collage2: file(relativePath: { eq: "tory-burch/tory-collage-2@2x.jpg" }) {
				...ImageFluidFull
			}
			collage3: file(relativePath: { eq: "tory-burch/tory-collage-3@2x.jpg" }) {
				...ImageFluidFull
			}

			addToCart: file(
				relativePath: { eq: "tory-burch/tory-burch-add@2x.jpg" }
			) {
				...ImageFluidFull
			}

			carousel01: file(
				relativePath: { eq: "tory-burch/raw/tory-carousel-01.jpg" }
			) {
				...ImageFluidFull
			}
			carousel02: file(
				relativePath: { eq: "tory-burch/raw/tory-carousel-02.jpg" }
			) {
				...ImageFluidFull
			}

			mobile1: file(relativePath: { eq: "tory-burch/tory-mobile-01@2x.jpg" }) {
				...ImageMobileScreen
			}
			mobile2: file(relativePath: { eq: "tory-burch/tory-mobile-02@2x.jpg" }) {
				...ImageMobileScreen
			}
			mobile3: file(relativePath: { eq: "tory-burch/tory-mobile-03@2x.jpg" }) {
				...ImageMobileScreen
			}
			mobile4: file(relativePath: { eq: "tory-burch/tory-mobile-04@2x.jpg" }) {
				...ImageMobileScreen
			}
			dsCollage: file(
				relativePath: { eq: "tory-burch/tory-ds-collage@2x.jpg" }
			) {
				...ImageFluidFull
			}
		}
	`)

	return (
		<div>
			<Helmet>
				<title>Tory Burch</title>
				<meta content="Tory Burch Case Study" property="og:title" />
				<meta content="Tory Burch Case Study" property="title:title" />
				<meta
					property="og:description"
					content="Free Association teamed up with Tory Burch on an overhaul of their e-commerce platform, evolving UX throughout the shopping funnel and establishing their first digital product design system."
				/>
				<meta
					name="description"
					content="Free Association teamed up with Tory Burch on an overhaul of their e-commerce platform, evolving UX throughout the shopping funnel and establishing their first digital product design system."
				/>
				<meta
					content="https://freeassociation.com/share/tory-burch.jpg"
					property="og:image"
				/>
				<meta
					content="https://freeassociation.com/share/tory-burch.jpg"
					property="twitter:image:src"
				/>
			</Helmet>
			<Slide layout={SLIDE_LAYOUT_MOCK_FULL_SCREEN}>
				<Container>
					<LayoutIndented>
						<Text>
							<h1 className="--eyebrow">Tory Burch</h1>
							<p className="--headline">E-commerce fit for luxury retail.</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutSplitHeading title="Overview">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<p>
									Free Association teamed up with Tory Burch on an overhaul of
									their e-commerce platform, evolving UX throughout the shopping
									funnel and establishing their first digital product design
									system. We served as the sole design team, and expanded
									in-house capabilities in product management, research and
									engineering. Our team defined new brand and product standards,
									reshaped content strategy, designed and prototyped UI
									patterns, built a robust product roadmap, supported A/B
									testing, delivered front-end code for key templates, and
									oversaw QA efforts through ongoing launches.
								</p>
							</Text>
						</LayoutSplitHeading>
						<LayoutSplitHeading title="Services">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<ul className="--unstyled-mediumUp --col-2">
									<li>Product Leadership</li>
									<li>Information Architecture</li>
									<li>Strategic Planning</li>
									<li>User Experience Design</li>
									<li>Project Management</li>
									<li>UI &amp; Visual Design</li>
									<li>UX &amp; Market Research</li>
									<li>Interaction Prototyping</li>
									<li>Testing &amp; Measurement</li>
									<li>Brand Systems &amp; Guidelines</li>
								</ul>
							</Text>
						</LayoutSplitHeading>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
				prefferedTheme={THEME_WHITE}
				style={{
					[media.small]: {
						marginBottom: '3.5rem',
					},
				}}
			>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<FullBleedImage
						fluidImageData={data.collage1.childImageSharp.fluid}
					/>
				</div>
			</Slide>
			<Slide
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
				prefferedTheme={THEME_WHITE}
				style={{
					[media.small]: {
						marginBottom: '3.5rem',
					},
				}}
			>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<FullBleedImage
						fluidImageData={data.collage2.childImageSharp.fluid}
					/>
				</div>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
					},
				}}
			>
				<Container>
					<Showcase
						images={[data.addToCart.childImageSharp.fluid]}
						layout={SHOWCASE_LAYOUT_1UP}
						chrome={true}
					/>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutCol2 verticalCadenceSize={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<Stat
							number="137"
							title="Components"
							description="We established Tory Burch’s first design system, and produced all new and accessible e-commerce UI patterns, mastered across many detailed states."
						/>
						<Stat
							number="2"
							title="Themes"
							description="Our system was designed to serve the Tory Burch brand and its sub-brand Tory Sport with a consistent UX hierarchy and shared shopping cart."
						/>
						<Stat
							number="42"
							title="Prototypes"
							description="We prototyped UI elements, interactions and motion details to set strong code standards and prepare signature effects for implementation."
						/>
						<Stat
							number="392"
							title="Design Comps"
							description="We created a comprehensive UI Inventory that gave teams visibility to the full range of complexity in the experience, including all variants and edge cases."
						/>
					</LayoutCol2>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
				style={{
					[media.small]: {
						marginBottom: '3.5rem',
					},
				}}
			>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<FullBleedImage
						fluidImageData={data.collage3.childImageSharp.fluid}
					/>
				</div>
			</Slide>

			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
					},
				}}
			>
				<Container maxWidth="82.5rem">
					<Showcase
						images={[
							data.carousel01.childImageSharp.fluid,
							data.carousel02.childImageSharp.fluid,
						]}
						layout={SHOWCASE_LAYOUT_2UP}
						chrome={true}
					/>
				</Container>
			</Slide>
			<Slide prefferedTheme={accentTheme}>
				<Container>
					<LayoutIndented>
						<Text>
							<blockquote>
								<p class="--large">
									"Herculean efforts all around! We value Free Association's
									partnership deeply, and are proud of all that we've
									accomplished together."
								</p>
								<footer>
									<p className="--small">
										<strong>Sarah Stark</strong>
										<br />
										VP, Digital Creative
									</p>
								</footer>
							</blockquote>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
						marginBottom: '3.5rem',
					},
				}}
			>
				<FluidContainer>
					<WorkShowcaseMobile
						layout={WORK_SHOWCASE_LAYOUT_MOBILE_4UP}
						items={[
							{
								src: data.mobile1.childImageSharp.fixed.src,
							},
							{
								src: data.mobile2.childImageSharp.fixed.src,
							},
							{
								src: data.mobile3.childImageSharp.fixed.src,
							},
							{
								src: data.mobile4.childImageSharp.fixed.src,
							},
						]}
						showChrome={true}
					/>
				</FluidContainer>
			</Slide>

			<Slide
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
				prefferedTheme={THEME_WHITE}
			>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<FullBleedImage
						fluidImageData={data.dsCollage.childImageSharp.fluid}
					/>
				</div>
			</Slide>
			<Slide>
				<Container>
					<LayoutIndented>
						<Text>
							<p className="--eyebrow">Next</p>
							<p className="--headline">
								<StandaloneLink to={nextPage.to}>
									{nextPage.title}
								</StandaloneLink>
							</p>
							<p className="--small">
								<InlineLink to="/work">All Work</InlineLink>
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
		</div>
	)
}

export default provideAccentTheme(ToryBurchPage, THEME_PEACH)
